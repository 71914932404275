
import { ReactSVG } from 'react-svg'
import { Button } from './Button'
import { useState } from 'react'
import { ModalDialog } from './ModalDialog'

export const FinantareItem = ({ icon, title, children }) => {

    const [modalOpen, setModalOpen] = useState(false)

    return (
        <>
            <div className="finantare__card">
                <div className="finantare__card__icon">
                    <ReactSVG src={icon} />
                </div>
                <h4>
                    {title.split(" ").slice(0, -1).join(" ")}
                    <br></br>
                    {title.split(" ").slice(-1).join(" ")}
                </h4>
                <Button text="DETALII" action={() => setModalOpen(true)} />
            </div>
            <ModalDialog
                open={modalOpen}
                closeAction={() => setModalOpen(false)}
                title={title}
            >
                {children}
            </ModalDialog>
        </>
    )
}