import { ContactForm } from '../components/ContactForm'
import { ContactInfo } from '../components/ContactInfo'
import { MapSection } from '../components/MapSection'
import { PageLayout } from '../components/PageLayout'
import GoToTop from '../hooks/GoToTop'
import contactData from '../data/contact';
import { useEffect } from 'react'

export const Contact = () => {

    useEffect(() => {
        if (document.querySelector('.page-layout').classList.contains('page-layout--gray')) {
            document.body.style.background = '#F8F9FB'
        } else {
            document.body.style.background = 'white'
        }

        return () => {
            document.body.style.background = 'white'
        }
    })

    return (
        <PageLayout title="Contact" description={'Contact'} noContainer gray>
            <div className="contact">
                <div className="container">
                    <ContactInfo data={contactData} />
                    <div className="dt-hide">
                        <MapSection page="contact" />
                    </div>
                    
                </div>
            </div>

            <div className="mb-hide">
                <MapSection page="contact" />
            </div>

            <GoToTop />
        </PageLayout>
    )
}