export const FooterNote = () => {
    return (
        <div className="footer__note">
            <div className="container">
            Notă: Imaginile sunt cu titlu de prezentare și e posibil să intervină modificări. Mobilierul este exclusiv cu titlu de prezentare, nefiind inclus în oferta dezvoltatorului.
            Oferta este valabilă în limita stocului disponibil. Dezvoltatorul își rezervă dreptul de a modifica oricând grila de prețuri pentru apartamentele disponibile. Pentru oferta completă și actualizată, vă invităm să luați legătura cu un consultant dedicat de vânzări.
            <br></br>
            <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO" target="_blank">
    <img src="/assets/images/anpc-sol.png" width="250" height="50"></img>
</a>

<a href="https://anpc.ro/ce-este-sal/" target="_blank">
    <img src="/assets/images/anpc-sal1-1.png" width="250" height="50"></img>
</a>
            </div>

        </div>

    )
}