import { CharacteristicCard } from './CharacteristicCard'
import structura from '../assets/images/structura.png'
import finisaje from '../assets/images/finisaje.png'
import spatii from '../assets/images/spatii.png'
import structura_2 from '../assets/images/structura@2x.png'
import finisaje_2 from '../assets/images/finisaje@2x.png'
import spatii_2 from '../assets/images/spatii@2x.png'
import { SectionTitle } from './SectionTitle'
import uuid from 'react-uuid'
import { Button } from '../components/Button'
import { Link } from 'react-router-dom'
export const Characteristics = () => {

    const data = [
        {
            title: 'STRUCTURA DE REZISTENȚĂ',
            image: structura,
            image_2: structura_2,
            details: [
                {
                    title: 'STRUCTURĂ',
                    list: [
                        'Structura este realizata din beton armat, utilizand un sistem structural mixt stalpi si pereti, cu plansee de 15 cm si o retea de grinzi perimetrale 30x70 cm iar cel de la demisol are grosimea de 20cm'

                        ,'Fundatia se realizeaza pe radier general din beton armat'
                        
                        ,'Constructia este realizata din 4 corpuri, fiecare avand un sistem structural alcatuit din pereti din beton armat si un sir interior de stalpi. Stalpii au sectiunea de 60x85cm. Peretii au grosimea de 40cm si 30cm.'
                        
                        ,'Inaltimile de nivel sunt 2.80m la demisol si 3.05 m la parter si etaje.'
                        
                        ,'Scarile sunt  realizate din beton armat.'
                        
                        ,'Structura de rezistenta a lifului este realizata din pereti de beton armat cu grosimea de 40cm.'
                        
                        ,'Elementele din beton armat sunt realizate din beton clasa C35/45 si armatura din Bst500S clasa de ductilitate C. '
                        
                        ,'Cantitatea de fier folosita este de 350 tone pe bloc.'
                    ]
                },
                {
                    title: 'ZIDĂRIE ȘI FINISAJE EXTERIOARE',
                    list: [
                        'Termosistem cu polistiren expandat cu grosime de 10 cm',
                        'Masă de șpaclu armată cu fibră de sticlă și tencuială decorativă',
                        'Balcoanele apartamentelor sunt placate cu plăci ceramice porțelanate pentru exterior și finisate cu tencuială decorativă',
                        'Tâmplăria exterioară este realizată din profile de PVC, Salamander/Weka, cu geam tripan cu protecție low – E'
                    ]
                }
            ]
        },
        {
            title: 'FINISAJE INTERIOARE',
            image: finisaje,
            image_2: finisaje_2,
            details: [
                {
                    title: 'DORMITOARE ȘI LIVING-URI',
                    list: [
                        'Pereții și tavanele vor fi finisate cu glet și zugrăvite în culoarea albă',
                        'Pardoseala va fi realizată cu parchet laminat flotant de 10 mm grosime, proveniență Austria Germania',
                        'Instalații pentru TV, priză simplă TV, două prize duble, întrerupător și încălzire în pardoseală'
                    ]
                },{
                    title: 'UȘĂ INTRARE/INTERIOARE',
                    list: [
                        'Ușă metalică cu închidere multipunct, finisaj nuanțe de lemn, izolată fonic, mâner, toc și pervaz metalic',
                        'Ușile interioare sunt de tip celular, finisate standard cu vopsea albă și lac, cu foaie plină, cu feronerie satinată'
                    ]
                }
               ,
                {
                    title: 'BĂILE',
                    list: [
                        'Dotate cu cadă/cabină de duș în funcție de tipul apartamentului și baterie',
                        'Lavoar cu baterie și picior',
                        'Vas WC monobloc cu rezervor',
                        'Pardoseală și pereți placate cu placi ceramice, pe înălțimea ușii',
                        'Peretele cu ușa băii finisat cu microdecorativă de culoare albă',
                        'Tavane finisate cu glet și zugrăvite în culoarea albă',
                        'Priză simplă, ventilator și întrerupător'
                    ]
                }, {
                    title: 'BUCĂTĂRIILE',
                    list: [
                        'Prețul afișat al apartamentelor este pentru bucătăria open space, la cerere se poate închide contra cost.',
                        'Pereți și tavane finisate cu glet și zugrăvite în culoare albă, perete cu blat de lucru placat cu faiantă de la înalțimea de 90 cm pana la înalțimea de 150 cm.',
                        'Pardoseala va fi placată cu plăci ceramice sau parchet',
                        'Instalație de gaze, senzor de gaz cu 3 prize duble și întrerupător'
                    ]
                }
                
            ]
        },
        {
            title: 'SPAȚII COMUNE',
            image: spatii,
            image_2: spatii_2,
            details: [
                {
                    title: 'CASA SCĂRII',
                    list: [
                        'Fiecare scară prevăzută cu 2 lifturi moderne',
                        'Placaje ceramice piatra naturală',
                        'Balustrade de protecție',
                        'Iluminare cu leduri pentru un consum redus al energiei electrice'
                    ]
                },
                {
                    title: 'EXTERIOARE',
                    list: [
                        'Ansamblul va fi dotat cu locuri de joacă pentru copii și zone de agrement pentru întreaga familie.'
                    ]
                },
                {
                    title: 'UTILITĂȚI',
                    list: [
                        'Apartamentele sunt contorizate individual și racordate la rețeaua electrică și la rețeaua de gaze, conectate prin fibra optică / Tv cablu'
                    ]
                },
            ]
        }
    ]
    return (
        <div className="characteristics">
            <SectionTitle white title_1="Caracteristici" title_2="CONSTRUCTIE" />

            <div className="characteristics__content">
                <div className="container">
                    {data && data.map(item => (
                        <CharacteristicCard
                            key={uuid()}
                            title={item.title}
                            image={item.image}
                            image_2={item.image_2}
                        >
                            <div className="characteristics__modal">
                                {item.details && item.details.map(detail => (
                                    <div key={uuid()}>
                                        <h5>{detail.title}</h5>
                                        <ul className="list">
                                            {detail.list && detail.list.map(item => (
                                                <li key={uuid()}>{item}</li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                                
                            </div>
                            <div className="proiect__button">
                            <Link to="/apartamente">
                                <Button text="Vezi Apartamente" />
                            </Link>
                        </div>
                        </CharacteristicCard>
                    ))}

                </div>
            </div>
        </div>
    )
}