import { ReactSVG } from 'react-svg'

export const ProiectCard = ({card}) => {

    const {icon, text,  value} = card

    return (
        <div className="proiect__card">
            <ReactSVG src={icon} />
            <h5>{text}</h5>
            <span>
                {value}
                {text === 'SPATII COMPERCIALE' && (
                    <span>MP</span>
                )}
            </span>
        </div>
    )
}