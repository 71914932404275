import { useState, useEffect } from "react"

const useScrollPosition = () => {
    // Store the state
    const [scrollPos, setScrollPos] = useState(window.pageYOffset)

    // Add and remove the window listener
    useEffect(() => {
        window.addEventListener("scroll", onScroll)
        return () => {
            window.removeEventListener("scroll", onScroll)
        }
    })

    if (typeof window === "undefined") return 500

    // On Scroll
    const onScroll = () => {
        setScrollPos(window.pageYOffset)
    }

    return scrollPos
    
}

export default useScrollPosition