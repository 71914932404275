import useMobile from '../hooks/useMobile'

export const AvailabilityValue = ({ disponibilitate, total }) => {

    const mobile = useMobile()
    return (
        <div className="apartamente__availability-value">
            {disponibilitate > 0 ? (
                <>
                <span>{'DISPONIBILE'}</span>
                <span></span>
                </>
            ) : (
                <>
                <span>{'INDISPONIBILE'}</span>
                </>
            )}
        </div>
    );
    
    
}