import { SectionTitle } from './SectionTitle'
import { StepCard } from './StepCard'
import { Button } from '../components/Button'
import { Link } from 'react-router-dom'
export const StepSection = () => {
    return (
        <div className="step">
            <SectionTitle title_1="Etapele" title_2="CONSTRUCTIEI" />
            <div className="step__content">
                <div className="container">
                    <StepCard
                        step="1"
                        blocuri="4"
                        apartamente="173"
                        spatii="1000"
                        finalizare="2021"
                        title={'Faza 1'}
                    >
                        <div className="about__img">
                            <img alt="faza 1" src="assets/images/Faza-1.png" />
                        </div>
                        <div className="proiect__button">
                            <Link to="/apartamente">
                                <Button text="Vezi Apartamente" />
                            </Link>
                        </div>

                    </StepCard>

                    <StepCard
                        step="2"
                        blocuri="4"
                        apartamente="212"
                        finalizare="2023"
                    >
                        <div className="about__img">
                            <img alt="faza 1" src="assets/images/Faza-2.png" />
                        </div>
                        <div className="proiect__button">
                            <Link to="/apartamente">
                                <Button text="Vezi Apartamente" />
                            </Link>
                        </div>
                    </StepCard>

                    <StepCard
                    step="3"
                    blocuri="5"
                    apartamente="257"
                    spatii="350"
                    finalizare="2025"
                    >
                        <div className="about__img">
                            <img alt="faza 1" src="assets/images/Faza-3.png" />
                        </div>
                        <div className="proiect__button">
                            <Link to="/apartamente">
                                <Button text="Vezi Apartamente" />
                            </Link>
                        </div>

                    </StepCard>
                    </div>
                </div>
            </div>
            )
}