import Zoom from 'react-medium-image-zoom'
import uuid from 'react-uuid'

export const ImageBar = ({ images }) => {
    return (
        <div className="image-bar">
            <div className="container">
                {images && images.map((image) => (
                    <Zoom key={uuid()}>
                        <img src={image.src} alt={image.alt} />
                    </Zoom>
                ))}
            </div>
        </div>
    )
}