import { useEffect } from 'react'

export const ModalDialog = ({ open, closeAction, title, children }) => {

    useEffect(() => {
        if (open) {
            document.documentElement.classList.add('overflow-hidden')

            window.history.pushState(null, null, window.location.href)
            window.onpopstate = function () {
                window.history.go(1);
            }

            return () => {
                document.documentElement.classList.remove('overflow-hidden')
                window.onpopstate = function () {
                    window.history.go();
                }
            }
        } else {
            window.onpopstate = function () {
                window.history.go();
            }
        }
    }, [open])

    return (
        open && (
            <div className="modal">
                <div className="modal__inner">
                    <span className="modal-close" onClick={closeAction}>
                        <i className="fal fa-times"></i>
                    </span>
                    {title &&
                        <div className="modal__header">
                            <h4>{title}</h4>
                        </div>
                    }
                    <div className="modal__body">
                        {children}
                    </div>
                </div>
            </div>
        )
    )
}