export const apartamenteData = [
    {
      "nr_camere": "studio",
      "tip": "2",
      "suprafata_construita": "58.73",
      "suprafata_utila_totala": "46.23",
      "numar_balcoane": "1",
      "numar_bai": "1",
      "compartimentare": null,
      "price": "74300",
      "promotional_price": "69900",
      "disponible": "1",
      "total": "8",
      "image": "/assets/images/faza2/STUDIO-TIP-2-ET2.png"
    },
    {
      "nr_camere": "studio",
      "tip": "3",
      "suprafata_construita": "46.64",
      "suprafata_utila_totala": "35.94",
      "numar_balcoane": "1",
      "numar_bai": "1",
      "compartimentare": null,
      "price": "57300",
      "promotional_price": "45000",
      "disponible": "1",
      "total": "27",
      "image": "/assets/images/faza2/STUDIO-TIP-3-ET2.png"
    },
    {
      "nr_camere": "studio",
      "tip": "4",
      "suprafata_construita": "55.12",
      "suprafata_utila_totala": "43.83",
      "numar_balcoane": "1",
      "numar_bai": "1",
      "compartimentare": null,
      "price": "VANDUT INTEGRAL",
      "promotional_price": "",
      "disponible": "0",
      "total": "9",
      "image": "/assets/images/faza2/STUDIO-TIP-4-ET2.png"
    },
    {
      "nr_camere": "studio",
      "tip": "5",
      "suprafata_construita": "42.96",
      "suprafata_utila_totala": "32.86",
      "numar_balcoane": "1",
      "numar_bai": "1",
      "compartimentare": null,
      "price": "52200",
      "promotional_price": "42500",
      "disponible": "1",
      "total": "9",
      "image": "/assets/images/faza2/STUDIO-TIP-5-ET2.png"
    },
    {
      "nr_camere": "2",
      "tip": "1s",
      "suprafata_construita": "59.98",
      "suprafata_utila_totala": "46.22",
      "numar_balcoane": "1",
      "numar_bai": "1",
      "compartimentare": null,
      "price": "74300",
      "promotional_price": "69900",
      "disponible": "3",
      "total": "24",
      "image": "/assets/images/faza2/STUDIO-TIP-1-ET2.png"
    },
    {
      "nr_camere": "2",
      "tip": "2",
      "suprafata_construita": "69.87",
      "suprafata_utila_totala": "55.19",
      "numar_balcoane": "1",
      "numar_bai": "1",
      "compartimentare": null,
      "price": "83900",
      "promotional_price": "77900",
      "disponible": "3",
      "total": "27",
      "image": "/assets/images/faza2/2C-TIP-2-ET2.png"
    },
    {
      "nr_camere": "2",
      "tip": "3a",
      "suprafata_construita": "68.57",
      "suprafata_utila_totala": "56.09",
      "numar_balcoane": "1",
      "numar_bai": "1",
      "compartimentare": null,
      "price": "83900",
      "promotional_price": "79200",
      "disponible": "4",
      "total": "27",
      "image": "/assets/images/faza2/2C-TIP-3A-ET2.png"
    },
    {
      "nr_camere": "2",
      "tip": "4a",
      "suprafata_construita": "72.35",
      "suprafata_utila_totala": "58.81",
      "numar_balcoane": "1",
      "numar_bai": "1",
      "compartimentare": null,
      "price": "87100",
      "promotional_price": "79900",
      "disponible": "2",
      "total": "9",
      "image": "/assets/images/faza2/2C-TIP-4A-ET2.png"
    },
    {
      "nr_camere": "2",
      "tip": "4b",
      "suprafata_construita": "72.20",
      "suprafata_utila_totala": "58.21",
      "numar_balcoane": "1",
      "numar_bai": "1",
      "compartimentare": null,
      "price": "87100",
      "promotional_price": "81000",
      "disponible": "6",
      "total": "27",
      "image": "/assets/images/faza2/2C-TIP-4B-ET2.png"
    },
    {
      "nr_camere": "3",
      "tip": "2a",
      "suprafata_construita": "92.11",
      "suprafata_utila_totala": "73.22",
      "numar_balcoane": "1",
      "numar_bai": "2",
      "compartimentare": null,
      "price": "111000",
      "promotional_price": "105000",
      "disponible": "1",
      "total": "9",
      "image": "/assets/images/faza2/3C-TIP-2A-ET2.png"
    },
    {
      "nr_camere": "3",
      "tip": "2b",
      "suprafata_construita": "87.40",
      "suprafata_utila_totala": "71.15",
      "numar_balcoane": "1",
      "numar_bai": "2",
      "compartimentare": null,
      "price": "111000",
      "promotional_price": "105000",
      "disponible": "1",
      "total": "9",
      "image": "/assets/images/faza2/3C-TIP-2B-ET2.png"
    },
    {
      "nr_camere": "3",
      "tip": "2c",
      "suprafata_construita": "92.56",
      "suprafata_utila_totala": "73.39",
      "numar_balcoane": "1",
      "numar_bai": "2",
      "compartimentare": null,
      "price": "111000",
      "promotional_price": "105000",
      "disponible": "3",
      "total": "27",
      "image": "/assets/images/faza2/3C-TIP-2C-ET2.png"
    }
  ]