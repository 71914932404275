import { InfoIconBadge } from './InfoIconBadge'
import plans from '../assets/icons/plans.svg'
import balcony from '../assets/icons/balcony.svg'
import room from '../assets/icons/room.svg'
import terrace from '../assets/icons/terrace.svg'
import bathing from '../assets/icons/bathing.svg'
import useMobile from '../hooks/useMobile'

export const AptDetais = ({ data, etaj, parter }) => {

    const mobile = useMobile()

    const {
        nr_camere,
        numar_bai,
        plan_image,
        tip,
        suprafata_construita,
        numar_balcoane,
        image,
        living,
        bucatarie,
        dormitor,
        dormitor_2,
        baie_2,
        baie,
        hol,
        vestibul,
        text_1,
        text_2,
    } = data

    return (
        <div className="apartamente__details">
            
            <div>
                
                <div className="apartamente__details__info">
                    
                    <h4>{!mobile && 'Apartament'} {nr_camere} {nr_camere.toLowerCase() !== 'studio' && 'camere'}</h4>
                    <h3>Tip {tip}</h3>
                    <div className="apartamente__details__info__area">
                    </div>

                    {mobile &&
                        <div className="apartamente__details__img">
                            <img src={image} alt="Plan" />
                        </div>
                    }
                    
                    <div className="apartamente__details__info__area">
                        <div>
                            <span className="text--bold">Suprafață construită: </span><span>{suprafata_construita} mp</span>
                        </div>
                    </div>

                    <div className="apartamente__details__info__description">
                        <p>
                            {text_1}
                        </p>
                        <p>
                            {text_2}
                        </p>
                    </div>
                </div>
                
                {!mobile && <div className="apartamente__details__img">
                    <img src={image} alt="Plan" />
                </div>}
            </div>
            <div>
                <div className="apartamente__details__icons">
                    <InfoIconBadge bg icon={room} text={`${nr_camere} ${nr_camere.toLowerCase() !== 'studio' ? 'camere' :''}`} /> 
                    <InfoIconBadge bg icon={plans} text={`${suprafata_construita}mp`} />
                    <InfoIconBadge bg icon={bathing} text={`${numar_bai} ${numar_bai > 1 ? 'bai':'baie'}`} />
                    <InfoIconBadge bg icon={balcony} text={`${numar_balcoane} balcon`} />
                    <InfoIconBadge bg icon={terrace} text={`terasa`} subText={`doar parter`} />
                </div>
            </div>
            <div className="apartamente__details__plan">
                <div className="apartamente__details__img">
                    <img src={plan_image} alt="Plan" />
                </div>
                <div className="apartamente__details__info">
                    <div className="apartamente__details__mpc-list">
                        <ul>
                            {etaj.length > 0 &&
                                <>
                                    <li>S. construita totală : {etaj[0].suprafata_construita} mp</li>
                                    <li>Balcon: {etaj[0].balcon} mp</li>
                                </>
                            }

                            <li>Living: {living} mp</li>
                            {bucatarie > 0 && <li>Bucătărie: {bucatarie} mp</li>}
                            {dormitor > 0 && <li>Dormitor: {dormitor} mp</li>}
                            {dormitor_2 > 0 && <li>Dormitor: {dormitor_2} mp</li>}
                            {baie > 0 && <li>Baie: {baie} mp</li>}
                            {baie_2 > 0 && <li>Baie: {baie_2} mp</li>}
                            {hol > 0 && <li>Hol: {hol} mp</li>}
                            {vestibul > 0 && <li>Vestibul: {vestibul} mp</li>}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
