import { ReactSVG } from 'react-svg'
import separator from '../assets/icons/separator.svg'

export const SectionTitle = ({ title_1, title_2, white }) => {
    return (
        <div className={`section-title ${white ?  'section-title--white' : ''}`}>
            <div className="section-title__font">{title_1}</div>
            <h3>{title_2}</h3>
            <ReactSVG src={separator} />
        </div>
    )
}