import { ReactSVG } from 'react-svg'

export const InfoIconBadge = ({icon, text, subText, bg}) => {
    return (
        <div className={`badge--info-icon ${bg ? 'badge--info-icon--bg' : ''}`}>
            <ReactSVG src={icon} />
            <span>{text}</span>
            {subText &&
                <div className="badge--info-icon__subtext">{subText}</div>
            }
        </div>
    )
}