import { ReactSVG } from 'react-svg'
import facebook_icon from '../assets/icons/facebook-brands.svg'
import whatsapp_icon from '../assets/icons/whatsapp.svg'

export const ContactInfo = ({ data }) => {

    const { address, whatsapp, phone_1, phone_2, email, facebook } = data

    return (
        <div className="contact__info">
            <div className="contact__info-item">
                <span><i className="fal fa-map-marker-alt"></i></span>
                <h5>
                    {address.split(" ").slice(0, -1).join(" ")}
                    <br></br>
                    {address.split(" ").slice(-1).join(" ")}
                </h5>
            </div>
            <div className="contact__info-item">
                <ReactSVG src={whatsapp_icon} />
                <a href={whatsapp} target="_blank" rel="noreferrer">
                    <p className="contact__info-item--wp">Contacteaza-ne pe WhatsApp</p>
                </a>
            </div>
            <div className="contact__info-item">
                <span><i className="fal fa-phone invert"></i></span>
                <a href={`tel:${phone_1}`}>
                    <p>{phone_1}</p>
                </a>
            </div>
            <div className="contact__info-item">
                <span><i className="fal fa-envelope"></i></span>
                <a className="dt-hide" href={`mailto:${email}`}>
                    <p>{email}</p>
                </a>
                <p className="mb-hide">{email}</p>
            </div>
            <div className="contact__info-item">
                <span className="contact__info-item--fb"><ReactSVG src={facebook_icon} /></span>
                <a href={facebook} target="_blank" rel="noreferrer">
                    <p>Urmareste-ne pe Facebook</p>
                </a>
            </div>
        </div>
    )
}
