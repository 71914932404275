import map from '../assets/images/map.jpg'
import map_2 from '../assets/images/map.jpg'

export const MapSection = ({ page }) => {
    return (
        <div className={`map`}>
            {page !== 'contact' &&
                <div className="map__img">
                    <img src={map} srcSet={map_2} alt="map" />
                </div>
            }
            <div className="mapouter">
                <div className="gmap_canvas">
                    <iframe title="google map" className="gmap_iframe" width="100%" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1566.4920964477596!2d25.98316!3d44.941667!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDTCsDU2JzMwLjAiTiAyNcKwNTgnNTkuNCJF!5e1!3m2!1sen!2sus!4v1631034656317!5m2!1sen!2sus&amp;q=44.941667,25.983160"></iframe>
                </div>
            </div>
        </div>
    )
}