import { Button } from './Button'
import { ModalDialog } from './ModalDialog'
import { useState } from 'react'

export const StepCard = ({ step, blocuri, apartamente, spatii, finalizare, children,title }) => {

    const [modalOpen, setModalOpen] = useState(false)

    return (
        <>
            <div className="step__card">
                <div className="step__card__badge">
                    {step}
                    {step == '1'  &&
                        <span>FINALIZAT</span>
                    }
                                     {step == '2'  &&
                        <span>FINALIZAT</span>
                    }
                                     {step == '3'  &&
                        <span>IN LUCRU</span>
                    }   

                </div>

                <ul>
                    {blocuri && <li>{blocuri} blocuri</li>}
                    {apartamente && <li>{apartamente} apartamente</li>}
                    {step == '2'  &&
                        <li>35 disponibile</li>
                    }   
                    {spatii && <li>{spatii}mp spatii comerciale</li>}  
                    {finalizare && <li>Finalizate {finalizare}</li>}
                </ul>

                <Button text="DETALII" action={() => setModalOpen(true)} />
            </div>

            <ModalDialog
                open={modalOpen}
                closeAction={() => setModalOpen(false)}
                title={title}
            >
                {children}
            </ModalDialog>
        </>
    )
}