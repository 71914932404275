import React, { Component } from "react";
import { PageLayout } from '../components/PageLayout'
import GoToTop from '../hooks/GoToTop'
import { useState, useEffect } from 'react'
import uuid from 'react-uuid'
import { Loading } from '../components/Loading'

export const Prototype = () => {
    let rooms
    const [data, setData] = useState(null)
    const [tronsoane, setData2] = useState(null)

    const [tipuri, setDataTipuri] = useState(null)
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        const ac = new AbortController()
        const signal = ac.signal
        const url = `https://rezidentialvest.ro/vamt/excel.php?type=prototype`

        const fetchData = async () => {
            try {
                const response = await fetch(url, { signal: signal })
                const json = await response.json()
                setData(json)
                console.log(json);
            } catch (error) {
                console.log("error", error)
            }
        }
        fetchData()

        return () => ac.abort()
    }, [])
    useEffect(() => {
        if (data === null) {
            setLoading(true)
        } else {
            setTimeout(() => {
                setLoading(false)
            }, 500);
        }
    }, [data, tronsoane])
    useEffect(() => {
        if (document.querySelector('.page-layout').classList.contains('page-layout--gray')) {
            document.body.style.background = '#F8F9FB'
        } else {
            document.body.style.background = 'white'
        }

        return () => {
            document.body.style.background = 'white'
        }
    })
    const [radioState, setRadioState] = useState(false);
    const handleChange = tags => (event) => {
        const ac = new AbortController()
        const signal = ac.signal
        const url = `https://rezidentialvest.ro/vamt/excel.php?type=prototype&rooms=${tags}`
        const fetchData = async () => {
            try {
                const response = await fetch(url, { signal: signal })
                const json = await response.json()
                setDataTipuri(json)
                //console.log(json);
                document.querySelector('button').setAttribute("className","prototype__btnactive")
            } catch (error) {
                console.log("error", error)
            }

        }
        fetchData()
    }
/*     

                if (document.querySelector('g#T1')) {
                    document.querySelector('g#T1').setAttribute("style", "")
                }
                if (document.querySelector('g#T2')) {
                    document.querySelector('g#T2').setAttribute("style", "")
                } if (document.querySelector('g#T3')) {
                    document.querySelector('g#T3').setAttribute("style", "")
                } if (document.querySelector('g#T4')) {
                    document.querySelector('g#T4').setAttribute("style", "")
                }

            } catch (error) {
                console.log("error", error)
                if (document.querySelector('g#T1')) {
                    document.querySelector('g#T1').setAttribute("style", "")
                }
                if (document.querySelector('g#T2')) {
                    document.querySelector('g#T2').setAttribute("style", "")
                } if (document.querySelector('g#T3')) {
                    document.querySelector('g#T3').setAttribute("style", "")
                } if (document.querySelector('g#T4')) {
                    document.querySelector('g#T4').setAttribute("style", "")
                }


const handleChange2 = tags => (event) => {
        document.getElementById(tags)
        if (document.querySelector('g#T1')) {
            document.querySelector('g#T1').setAttribute("style", "display:none")
        }
        if (document.querySelector('g#T2')) {
            document.querySelector('g#T2').setAttribute("style", "display:none")
        }
        if (document.querySelector('g#T3')) {
            document.querySelector('g#T3').setAttribute("style", "display:none")
        }
        if (document.querySelector('g#T4')) {
            document.querySelector('g#T4').setAttribute("style", "display:none")
        }
        document.querySelector('g#' + tags).setAttribute("style", "")

    } */

    return (
        <>
            <PageLayout title="Prototype" description={'Prototype page description'} noContainer gray>
                {loading ? (
                    <Loading />
                ) : (<>
                    <div className="prototype">
                        <div className="container">
                            <div className="prototype-row">
                                {data &&
                                    data.map((item, i) =>
                                    (
                                        <div key={uuid()} className="prototype-col">
                                            <button className="prototype__btn" key={uuid()} id={item.nr_camere} name="nr_camere" value={item.nr_camere} onClick={handleChange(item.nr_camere)}>{item.nr_camere} camere</button>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="prototype-row">
                                {
                                    tipuri &&
                                    tipuri.map((item, i) =>
                                    (

                                        <div key={uuid()} className="prototype-col">
                                            <button className="prototype__btn" key={uuid()}  id={item.tip} name="tip" value={item.tip} onClick={handleChange(item.tip)}>{item.tip}</button>
                                        </div>


                                    ))
                                }
                            </div>
                            <div className="prototype__imgcontainers" >
                                <div className="prototype__svgs">
                                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14213 7995">

                                        {
                                            tronsoane &&
                                            tronsoane.map((item, i) =>
                                            (
                                                <g dangerouslySetInnerHTML={{ __html: item.cod }} />

                                            ))

                                        }
                                    </svg>
                                </div>
                                <div className="prototype__png">
                                    <img className="prototype__png" src="https://rezidentialvest.ro/vamt/images/tronsoane/TALL.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </>)
                }
                <GoToTop />
            </PageLayout>
        </>)


}