import uuid from 'react-uuid'
import image1 from '../assets/images/about1.png'
import image1_2 from '../assets/images/about1@2x.png'
import image2 from '../assets/images/about2.png'
import image2_2 from '../assets/images/about2@2x.png'
import image3 from '../assets/images/about3.png'
import image3_2 from '../assets/images/about3@2x.png'
import image4 from '../assets/images/about4.png'
import image4_2 from '../assets/images/about4@2x.png'
import { SectionTitle } from './SectionTitle'
   
export const AboutSection = () => {

    const content = [
        {
            title: 'LOCUINTE MODERNE',
            text: 'Este soluția pentru cei in căutarea unei locuințe moderne, care oferă atât confortul și liniștea mult dorite, cât și amplasarea strategică, facilitând astfel accesul către principalele puncte de interes ale orașului Ploiești.',
            image: image1,
            imageSrcet: image1_2
        },
        {
            title: 'CONSTRUIM O COMUNITATE',
            text: 'Proiectul imobiliar presupune nu numai construirea a 600 de apartamente, ci și creearea unei comunități. De aceea, atenția va fi îndreptată și asupra spațiilor verzi, spațiilor comerciale și parcărilor subterane, creând un ambient plăcut și eficace.',
            image: image2,
            imageSrcet: image2_2
        },
        {
            title: 'ETAPELE PROIECTULUI',
            text: 'Prima etapă a proiectului reprezentând 173 de apartamente și 1,000 mp de spații comerciale va fi finalizată în luna decembrie 2021, urmând ca cea de-a doua și cea de-a treia etapa sa se finalizeze in 2023, respectiv 2025.',
            image: image3,
            imageSrcet: image3_2
        },
        {
            title: 'CALITATE',
            text: 'Calitatea materialelor folosite, atenția la detaliile de execuție și sustenabilitatea proiectului ghideaza dezvoltarea viitorului cartier din zona de vest a orasului Ploiești.',
            image: image4,
            imageSrcet: image4_2
        }
    ]
    return (
        <div className="about">
            <SectionTitle  title_2="VAMT  | REZIDENȚIAL VEST" />

            {content && content.map(item => (
                <div key={uuid()} className="about-row">
                    <div className="about-col">
                        <h5>{item.title}</h5>
                        <p>{item.text}</p>
                    </div>

                    <div className="about-col">
                        <img src={item.image} srcSet={item.image1_2} alt={item.title} />
                    </div>
                </div>
            ))}
        </div>
    )
}