import { useState } from 'react'
import uuid from 'react-uuid'
import { Button } from './Button'
import useMobile from '../hooks/useMobile'
import ReactGA from 'react-ga'
import Loader from 'react-loader-spinner'

export const Table = ({ headings, values, pdf, tip, nr_camere, faza }) => {

    const mobile = useMobile()
    const [PDFLoading, setPDFLoading] = useState(false)


    const fetchPDF = () => {
        setPDFLoading(true)
        fetch(pdf)
            .then(res => res.blob())
            .then(blob => {
                setPDFLoading(blob ? false : true)
            })
    }
    return (
        <div className="table">
            <table>
                <thead>
                    <tr>
                        {headings && headings.map(heading => (
                            <th key={uuid()}>{heading}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {values && values.map(value => (
                        <tr key={uuid()}>
                            <td className={value.pret==='VANDUT' ? ('table__etaj__vandut'):('table__etaj')}><span>{value.etaj}</span></td>
                            <td className={value.pret==='VANDUT' ? ('table__utila__vandut'):('table__utila')}><span>{value.utila} mp</span></td>
                            <td className={value.pret==='VANDUT' ? ('table__pret__vandut'):('table__pret')}><span>{value.pret} {value.pret !== "VANDUT" && '€'}</span></td> 
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="table__button">
                {mobile &&
                    <a href={pdf} onClick={fetchPDF} download>
                        <Button
                            action={() => ReactGA.event({
                                category: 'PDF Download',
                                action: JSON.stringify({ faza }) + JSON.stringify({ nr_camere }) + JSON.stringify({ tip }),
                            })}>

                            {PDFLoading ? (
                                <Loader
                                    type="ThreeDots"
                                    color="white"
                                    width={50}
                                    height={50}
                                />
                            ) : (
                                <>
                                    Descarca PDF <i className="fas fa-download"></i>
                                </>
                            )}
                        </Button>
                    </a>
                }
            </div>
        </div>
    )
}
