import { FinantareItem } from '../components/FinantareItem'
import { PageLayout } from '../components/PageLayout'
import loan from '../assets/icons/loan-money.svg'
import mortgage_loan from '../assets/icons/mortgage-loan.svg'
import house from '../assets/icons/house.svg'
import mortgage from '../assets/icons/mortgage.svg'
import uuid from 'react-uuid'
import GoToTop from '../hooks/GoToTop'
import { Button } from '../components/Button'

export const Finantare = () => {

    const data = [
        {
            title: 'FONDURI PROPRII',
            icon: loan,
            details: [
                'Metoda îți asigură cel mai mic cost din ofertă și îți dă posibilitatea să economisești pentru alte lucruri indispensabile locuinței.',
                'Optează pentru un avans de 30% la încheierea contractului, iar apoi plătești defalcat în funcție de progresul lucrărilor.',
                'În acest fel îți gestionezi eficient bugetul, profitând de alte oportunități sau lucruri care îți fac plăcere.',
            ],
            payment: [
                '30% avans la încheierea antecontractului',
                '70% la recepția apartamentului'
            ]
        },

        {
            title: "PRIMA CASA",
            icon: house,
            details: [
                'Scopul principal al acestui program este să permită accesarea creditelor pentru persoane tinere. Acest tip de de creditare se face cu garantarea statului între 40% - 50% din valoarea înprumuturilor, condiția fiind ca băncile să ofere credite mai ieftine.'
            ],
            payment: [
                '15% avans la încheierea antecontractului',
                '85% credit imobiliar Prima Casă'
            ],

        },
        {
            title: 'CREDIT IPOTECAR',
            icon: mortgage,
            details: [
                'Se refera la accesarea unui credit bancar, se va achita un avans intre 15% si 30% la semnarea antecontractului de vanzare - cumparare, diferenta se achita din credit la receptia apartamentului.',
            ],
            payment: [
                '15% - 30% avans la incheierea antecontractului ',
                'diferenta se achita prin credit la receptia apartamentului'
            ],
        }
    ]

    return (
        <PageLayout title="Finantare" description={'Primul proiect din Prahova cu posibilitatea de finanțare prin credit la dezvoltator pe o perioadă de până la 10 ani.'}>
            <div className="finantare">
                {data && data.map(item => (
                    <FinantareItem key={uuid()} title={item.title} icon={item.icon}>
                        <div className="text-left">
                            {item.details && item.details.map(detail => (
                                <div key={uuid()}>
                                    <p>{detail}</p>
                                    <br></br><br></br>
                                </div>
                            ))}
                            <ul className="list">
                                {item.payment && item.payment.map(pmt => (
                                    <li key={uuid()}>{pmt}</li>
                                ))}
                            </ul>
                        </div>
                    </FinantareItem>
                ))}
                <div className="finantare__pdf">
                <br></br>
                <br></br>
                <a href={'/assets/pdf/finantare.pdf'} download>
                    <Button>
                        
                        Descarca PDF <i className="fas fa-download"></i>
                    </Button>
                    </a>
                </div>
            </div>
            <GoToTop />
        </PageLayout>
    )
}