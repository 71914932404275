import { useEffect } from 'react'
import { PageLayout } from '../components/PageLayout'
import GoToTop from '../hooks/GoToTop'
import { Loading } from '../components/Loading'
import useMobile from '../hooks/useMobile'
import ImageGallery from "react-image-gallery"
import {images} from '../data/gallery_images'
import imageGallery from 'react-image-gallery'

export const Galerie = () => {


    const mobile = useMobile()

    const galleryImages = images.map((url) => ({
        original: url,
        thumbnail: `${url}?w=250&h=150`,
    }));


    useEffect(() => {
        if (document.querySelector('.page-layout').classList.contains('page-layout--gray')) {
            document.body.style.background = '#F8F9FB'
        } else {
            document.body.style.background = 'white'
        }

        return () => {
            document.body.style.background = 'white'
        }
    })

    function scroll() {
        var offset = 0
        if (mobile) {
            offset = 230
        } else {
            offset = 480
        }

        window.scrollTo({
            top: offset,
            behavior: 'smooth'
        })
    }

    useEffect(() => {
        scroll()

    })

    return (
        <PageLayout title='Galerie' description={'Galerie'}>

            {!imageGallery ? (
                <Loading />
            ) : (
                <ImageGallery items={galleryImages} />
            )}
            <GoToTop />
        </PageLayout>
    )
}