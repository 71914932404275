import { Footer } from './Footer'
import { Helmet } from 'react-helmet'

export const PageLayout = ({ title, children, noContainer, gray, description }) => {
    return (
        <>
            <Helmet>
                <title>{title} | VAMT | Rezidențial Vest</title>
                {description &&
                    <meta name="description" content={description} />
                }
            </Helmet>
            <div className="page-layout__img">
                <div className="page-layout__title">
                    <h1>
                        {title}
                    </h1>
                </div>
            </div>
            <div className={`page-layout ${gray ? 'page-layout--gray' : ''}`}>
                <div className="page-layout__content" id="content">
                    <div className="page-layout__bg">
                        <div className={`${noContainer ? '' : 'container'}`}>
                            {children}
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
