export const images = [
    "/assets/images/galerie/image-1.jpeg",
    "/assets/images/galerie/image-2.jpeg",
    "/assets/images/galerie/image-3.jpeg",
    "/assets/images/galerie/image-4.jpg",
    "/assets/images/galerie/image-5.jpg",
    "/assets/images/galerie/image-6.jpg",
    "/assets/images/galerie/image-7.jpg",
    "/assets/images/galerie/image-8.jpg",
    "/assets/images/galerie/image-9.jpg",
    "/assets/images/galerie/image-10.jpeg",
    "/assets/images/galerie/image-11.jpg",
    "/assets/images/galerie/image-12.jpg",
    "/assets/images/galerie/image-13.jpeg",
    "/assets/images/galerie/image-14.jpg",
    "/assets/images/galerie/image-15.jpg",
    "/assets/images/galerie/image-16.jpg",
    "/assets/images/galerie/image-17.jpg",
    "/assets/images/galerie/image-18.jpeg",
    "/assets/images/galerie/image-19.jpg",
    "/assets/images/galerie/image-20.jpg",
    "/assets/images/galerie/image-21.jpg",
    "/assets/images/galerie/image-22.jpg",
    "/assets/images/galerie/image-23.jpg",
    "/assets/images/galerie/image-24.jpeg",
    "/assets/images/galerie/image-25.jpeg",
    "/assets/images/galerie/image-26.jpg",
    "/assets/images/galerie/image-27.png",
    "/assets/images/galerie/image-28.jpg"
];
