import Loader from "react-loader-spinner"

export const Loading = () => {
    return (
        <div className="loading">
            <Loader
                type="MutatingDots"
                color="#8FD1B6"
                secondaryColor="#CEEBDF"
                height={100}
                width={100}
            />
        </div>
    )
}