import { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { Navbar } from './components/Navbar';
import { Apartament } from './pages/Apartament';
import { ApartamenteList } from './pages/ApartamenteList';
import { Contact } from './pages/Contact';
import { Finantare } from './pages/Finantare';
import { Galerie } from './pages/Galerie';
import { Proiect } from './pages/Proiect';
import { Prototype } from './pages/Prototype';
import './styles/styles.scss';
import smoothscroll from 'smoothscroll-polyfill';
import CookieConsent, { Cookies } from 'react-cookie-consent'; // Removed getCookieConsentValue import
import TagManager from 'react-gtm-module';

function App() {
  let location = useLocation();

  // Initialize polyfill for smooth scrolling
  smoothscroll.polyfill();

  // Load Google Tag Manager on app load (without checking cookie consent)
  useEffect(() => {
    handleAcceptCookie(); // Automatically load GTM - NEW
  }, []); // NEW

  // Trigger page view event when the route changes
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pageview',
      page: location.pathname + location.search,
    });
  }, [location]);

  // Function to handle GTM initialization
  const handleAcceptCookie = () => {
    // Inject Google Consent Mode Script
    if (process.env.REACT_APP_GTM_ID) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: 'consent', ad_storage: 'granted', analytics_storage: 'granted' });

      // Initialize GTM
      TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
    }
  };

  // Function to handle the decline of cookies
  const handleDeclineCookie = () => {
    // Inject Google Consent Mode Script with denied status
    //window.dataLayer = window.dataLayer || [];
    //window.dataLayer.push({ event: 'consent', ad_storage: 'denied', analytics_storage: 'denied' });
    // Remove Google Analytics cookies if consent is declined
    //Cookies.remove('_ga');
    //Cookies.remove('_gat');
    //Cookies.remove('_gid');
  };

  return (
    <>
      {/* Navigation and Routes */}
      <Navbar />

      <div className='wrapper'>
        <Switch>
          <Route exact path='/'>
            <Proiect />
          </Route>

          <Route exact path='/finantare'>
            <Finantare />
          </Route>

          <Route exact path='/apartamente'>
            <ApartamenteList />
          </Route>

          <Route path='/apartamente/:rooms/:type'>
            <Apartament />
          </Route>

          <Route path='/galerie'>
            <Galerie />
          </Route>

          <Route exact path='/contact'>
            <Contact />
          </Route>

          <Route exact path='/prototype'>
            <Prototype />
          </Route>
        </Switch>
        <a  href='https://api.whatsapp.com/send?phone=40376444440' target='_blank' rel='noreferrer'>
          <button type='button' class='btn btn-success whatsapp-button'>
            <i class='fab fa-whatsapp fa-2x' aria-hidden='true'></i>
          </button>
        </a>
      </div>
    </>
  );
}

export default App;
