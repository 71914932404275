import { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import { AptDetailCard } from '../components/AptDetailCard';
import { FloatMenu } from '../components/FloatMenu';
import { Loading } from '../components/Loading';
import { PageLayout } from '../components/PageLayout';
import GoToTop from '../hooks/GoToTop';
import useMobile from '../hooks/useMobile';
import { apartamenteData } from '../data/apartamente';

export const ApartamenteList = () => {
  const mobile = useMobile();
  const [loading, setLoading] = useState(true);

  const twoCamere = apartamenteData.filter((item) => item.nr_camere === '2');
  const threeCamere = apartamenteData.filter((item) => item.nr_camere === '3');
  const studio = apartamenteData.filter((item) => item.nr_camere === 'studio');

  useEffect(() => {
    if (apartamenteData === null) {
      setLoading(true);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (document.querySelector('.page-layout').classList.contains('page-layout--gray')) {
      document.body.style.background = '#F8F9FB';
    } else {
      document.body.style.background = 'white';
    }

    return () => {
      document.body.style.background = 'white';
    };
  });
  function scroll() {
    var offset = 0;
    if (mobile) {
      offset = 230;
    } else {
      offset = 575;
    }

    window.scrollTo({
      top: offset,
      behavior: 'smooth',
    });
  }
  scroll();
  return (
    <PageLayout
      title='Apartamente'
      gray
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          <FloatMenu
            achorOffset={-200}
            scrollspyOffset={500}
            items={[
              {
                name: `2 camere`,
                href: `camere-2`,
              },
              {
                name: `3 camere`,
                href: `camere-3`,
              },
              {
                name: `studio`,
                href: `studio`,
              },
            ]}
          />
          <div className='apartamente__list'>
            {twoCamere.map((data) => (
              <div key={uuid()} id='camere-2'>
                <AptDetailCard data={data} />
              </div>
            ))}
            {threeCamere.map((data) => (
              <div key={uuid()} id='camere-3'>
                <AptDetailCard data={data} />
              </div>
            ))}
            {studio.map((data) => (
              <div key={uuid()} id='studio'>
                <AptDetailCard data={data} />
              </div>
            ))}
          </div>
        </>
      )}
      <GoToTop />
    </PageLayout>
  );
};
