import hero_video from '../assets/videos/Rezidential_Vest_8_2.mp4';
import { Link } from 'react-router-dom';
import { Button } from '../components/Button';

export const Hero = () => {
  return (
    <>
      <div className='hero'>
        <video playsinline='playsinline' autoplay='autoplay' muted='muted' loop='loop'>
          <source src={hero_video} type='video/mp4' />
        </video>
        <div className='hero__button'>
          <Link to='/apartamente'>
            <Button text='Apartamente' />
          </Link>
        </div>
      </div>
    </>
  );
};
