import { useState } from 'react'
import { Button } from './Button'
import { ModalDialog } from './ModalDialog'

export const CharacteristicCard = ({ title, image, image_2, children }) => {

    const [modalOpen, setModalOpen] = useState(false)

    return (
        <>
            <div className="characteristics__card">
                <div className="characteristics__card-img">
                    <img src={image} srcSet={image_2} alt={title} />
                </div>

                <div className="characteristics__card-content">
                    <h4>{title}</h4>
                    <Button text="Detalli" action={() => setModalOpen(true)} />
                </div>
            </div>

            <ModalDialog
                open={modalOpen}
                closeAction={() => setModalOpen(false)}
                title={title}
            >
                {children}
            </ModalDialog>
        </>
    )
}