import { useState, useEffect } from "react"

const useMobile = () => {
    // Store the state
    const [mobile, setMobile] = useState(window.innerWidth < 1024 ?  true : false)

    useEffect(() => {
        window.addEventListener("resize", () => {

            if (window.innerWidth < 1024) {
                setMobile(true)
            } else {
                setMobile(false)
            }
        })
    }, []);

    return mobile
}

export default useMobile